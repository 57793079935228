import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" >
<path d="M6155 10799 c-321 -30 -545 -95 -553 -160 -11 -96 264 -198 928 -345
676 -149 1333 -332 1662 -463 87 -34 237 -109 283 -140 17 -12 33 -21 38 -21
23 0 -191 265 -311 386 -219 220 -421 369 -676 498 -245 124 -519 208 -791
240 -140 17 -425 19 -580 5z"/>
<path d="M5009 10421 c-125 -83 -365 -275 -393 -314 -16 -22 -20 -43 -20 -100
0 -81 24 -140 88 -214 75 -86 240 -188 391 -242 33 -11 278 -64 545 -116 267
-53 550 -109 630 -125 158 -32 1488 -295 1980 -391 168 -33 334 -66 370 -74
36 -8 151 -31 255 -50 685 -128 1065 -239 1252 -366 92 -62 123 -107 123 -175
0 -47 -20 -82 -72 -130 -114 -105 -334 -202 -693 -307 -179 -53 -536 -137
-581 -137 -6 0 -18 27 -27 59 -16 56 -78 149 -126 189 -193 158 -487 274 -906
357 -38 8 -155 32 -260 55 -104 22 -233 50 -285 60 -148 30 -319 66 -505 105
-93 20 -233 49 -310 65 -77 16 -216 45 -310 65 -93 20 -287 60 -430 90 -143
30 -294 61 -335 70 -41 9 -192 41 -335 71 -260 54 -363 87 -467 151 -21 13
-40 23 -43 23 -4 0 -36 21 -73 46 -96 67 -185 161 -224 241 -31 63 -33 74 -33
169 0 101 0 102 -18 75 -26 -37 -119 -225 -147 -294 -13 -33 -28 -61 -34 -63
-12 -4 -228 -31 -511 -63 -617 -71 -718 -86 -930 -131 -49 -11 -178 -38 -285
-60 -679 -143 -1205 -315 -1452 -474 -121 -78 -198 -175 -198 -250 1 -41 30
-104 70 -150 192 -219 905 -456 1890 -630 242 -43 549 -101 640 -121 36 -8
432 -87 880 -175 448 -89 856 -170 905 -180 50 -11 320 -65 600 -120 281 -55
539 -107 575 -115 36 -8 162 -33 280 -55 667 -128 1049 -270 1176 -437 46 -60
51 -104 20 -166 -14 -28 -24 -52 -22 -54 10 -11 314 212 474 349 82 69 58 237
-50 354 -76 83 -235 180 -381 234 -28 10 -272 62 -542 115 -269 53 -611 120
-760 150 -333 66 -927 184 -1135 224 -85 17 -265 53 -400 80 -331 66 -994 196
-1105 216 -447 81 -889 213 -1060 315 -65 40 -133 104 -146 138 -13 35 -11 89
6 121 8 16 37 49 66 75 159 143 569 290 1173 420 65 15 122 28 126 31 5 3 16
-17 25 -44 48 -147 209 -303 420 -408 132 -66 347 -134 526 -167 303 -55 3236
-649 3282 -665 159 -55 297 -162 350 -272 42 -84 61 -197 50 -280 -9 -67 -9
-68 10 -51 10 9 54 89 98 178 79 161 80 162 117 168 53 8 739 89 1027 121 148
17 637 117 985 201 764 185 1200 376 1295 567 32 64 32 100 0 164 -124 248
-822 499 -1945 699 -234 42 -546 103 -2465 485 -357 71 -877 174 -1155 229
-719 143 -979 214 -1207 327 -114 57 -211 131 -237 182 -28 53 -24 116 9 165
13 20 21 36 16 36 -4 0 -55 -31 -112 -69z"/>
<path d="M4260 6842 c0 -8 127 -178 172 -232 17 -19 82 -88 146 -154 381 -387
846 -632 1367 -718 177 -30 572 -32 775 -5 225 30 405 80 440 122 25 30 7 69
-50 106 -100 66 -399 164 -678 223 -406 86 -770 175 -1212 296 -371 101 -711
226 -883 324 -43 24 -77 41 -77 38z"/>
<path d="M8540 5835 l0 -30 110 3 c79 2 110 0 110 -8 0 -16 -140 -242 -159
-257 -13 -10 -5 -12 42 -13 37 0 56 4 52 10 -4 6 30 71 74 144 45 74 81 145
81 158 l0 23 -155 0 -155 0 0 -30z"/>
<path d="M9160 5835 l0 -30 116 3 c98 3 116 1 111 -10 -3 -8 -41 -72 -85 -141
l-81 -127 50 0 c41 0 49 3 49 18 0 10 34 73 75 141 44 72 75 133 75 149 l0 27
-155 0 -155 0 0 -30z"/>
<path d="M10361 5858 c-88 -15 -131 -68 -131 -161 0 -114 49 -167 153 -167 71
0 117 20 141 61 36 60 0 127 -79 148 -43 11 -105 2 -130 -19 -13 -10 -15 -8
-15 11 1 46 44 79 105 79 37 0 74 -14 86 -33 6 -8 39 40 39 56 0 5 -68 25
-100 29 -14 2 -45 0 -69 -4z m89 -186 c18 -15 22 -25 16 -46 -16 -66 -156 -58
-156 9 0 50 94 75 140 37z"/>
<path d="M10724 5860 c-109 -16 -165 -117 -127 -230 24 -73 63 -100 146 -100
79 0 133 29 148 80 28 91 -76 162 -183 125 -58 -20 -59 -20 -46 15 14 41 59
64 115 57 24 -2 52 -12 63 -22 18 -16 19 -15 34 14 9 16 16 32 16 34 0 4 -64
22 -105 29 -11 2 -39 1 -61 -2z m86 -188 c49 -40 -9 -103 -82 -88 -41 8 -58
23 -58 52 0 49 94 73 140 36z"/>
<path d="M7725 5695 l0 -165 123 0 c132 0 182 12 201 48 17 32 13 80 -9 102
-24 24 -25 33 -5 50 25 21 19 77 -11 105 -26 24 -32 25 -163 25 l-136 0 0
-165z m236 105 c21 -12 25 -40 7 -58 -7 -7 -43 -12 -90 -12 l-78 0 0 40 0 40
71 0 c39 0 79 -5 90 -10z m13 -139 c20 -17 21 -45 1 -61 -9 -7 -47 -13 -93
-14 l-77 -1 -3 47 -3 48 79 -3 c48 -2 86 -8 96 -16z"/>
<path d="M8125 5695 l0 -165 44 0 c36 0 41 2 32 14 -8 9 -11 48 -9 112 l3 99
97 -100 c54 -55 98 -106 98 -112 0 -9 15 -13 43 -13 l42 0 0 165 0 165 -40 0
-40 0 5 -95 c3 -53 3 -100 1 -106 -5 -16 -181 162 -181 183 0 15 -9 18 -47 18
l-48 0 0 -165z"/>
<path d="M8985 5840 c6 -19 -7 -26 -37 -21 -5 0 -8 -12 -8 -29 0 -26 4 -30 26
-30 l26 0 -4 -115 -3 -115 35 0 35 0 0 165 0 165 -38 0 c-34 0 -38 -2 -32 -20z"/>
<path d="M9590 5853 c-60 -22 -77 -86 -35 -126 l26 -23 -26 -12 c-52 -24 -53
-98 -1 -135 43 -30 165 -36 216 -10 68 35 78 106 21 141 l-29 18 24 21 c42 38
27 101 -30 123 -28 10 -140 13 -166 3z m149 -58 c35 -35 -38 -77 -101 -59 -25
7 -34 16 -36 36 -3 21 3 28 25 37 35 13 92 6 112 -14z m2 -136 c39 -39 2 -79
-74 -79 -54 0 -77 15 -77 51 0 35 23 47 88 48 30 1 48 -5 63 -20z"/>
<path d="M9950 5837 c-50 -33 -75 -82 -75 -147 0 -100 60 -160 158 -160 90 0
147 43 147 110 -1 38 -12 57 -50 83 -32 22 -120 22 -162 1 l-31 -16 7 29 c4
15 18 38 32 51 33 29 110 31 146 3 l25 -20 15 33 16 32 -44 12 c-73 20 -146
15 -184 -11z m150 -167 c11 -11 20 -27 20 -35 0 -25 -42 -55 -76 -55 -35 0
-84 18 -84 31 0 4 -3 14 -6 22 -18 49 104 79 146 37z"/>
<path d="M2780 5235 l0 -85 190 0 190 0 2 -382 3 -383 85 0 85 0 5 380 5 380
188 3 187 2 0 85 0 85 -470 0 -470 0 0 -85z"/>
<path d="M3852 4853 l3 -468 425 0 425 0 3 88 3 87 -341 0 -340 0 0 100 0 100
275 0 275 0 0 90 0 90 -275 0 -275 0 0 105 0 105 340 0 340 0 0 85 0 85 -430
0 -430 0 2 -467z"/>
<path d="M4880 4850 l0 -470 430 0 c329 1 430 4 431 13 1 19 1 141 0 155 -1 9
-76 12 -341 12 l-340 0 0 100 0 100 275 0 275 0 0 90 0 90 -275 0 -275 0 0
105 0 105 339 0 c304 0 340 2 343 16 2 9 2 47 0 85 l-4 69 -429 0 -429 0 0
-470z"/>
<path d="M5860 5235 l0 -85 190 0 190 0 0 -379 c0 -336 2 -380 16 -385 23 -9
137 -7 151 2 10 6 13 98 15 383 l3 374 188 3 187 2 0 85 0 85 -470 0 -470 0 0
-85z"/>
<path d="M6932 4853 l3 -468 425 0 425 0 3 88 3 87 -341 0 -340 0 0 100 0 100
275 0 275 0 0 90 0 90 -275 0 -275 0 0 105 0 105 340 0 340 0 0 85 0 85 -430
0 -430 0 2 -467z"/>
<path d="M8045 5296 c-23 -14 -51 -43 -65 -68 l-25 -43 0 -330 c0 -315 1 -332
21 -370 11 -22 40 -53 63 -70 l43 -30 401 0 402 0 3 88 3 87 -381 0 -380 0 2
293 3 292 378 3 377 2 0 85 0 85 -402 0 -403 0 -40 -24z"/>
<path d="M9062 4853 l3 -468 85 0 85 0 3 188 2 187 303 -2 302 -3 0 -180 c0
-108 4 -183 10 -187 12 -9 127 -10 149 -2 14 5 16 56 16 470 l0 464 -85 0 -85
0 0 -174 c0 -96 -3 -181 -6 -190 -5 -14 -42 -16 -305 -16 l-299 0 0 190 0 190
-90 0 -90 0 2 -467z"/>
<path d="M1944 4344 c-177 -29 -353 -119 -452 -229 -52 -59 -103 -151 -123
-225 -17 -61 -17 -242 0 -310 117 -469 798 -619 1444 -318 48 23 87 45 87 49
0 5 -13 15 -30 24 l-30 16 0 163 0 164 30 14 c17 7 30 19 30 25 0 10 -73 13
-336 13 l-336 0 -29 31 c-16 17 -33 28 -39 24 -6 -4 -10 -58 -10 -135 0 -139
9 -163 42 -117 l20 27 189 0 189 0 0 -84 0 -85 -57 -21 c-88 -33 -188 -51
-309 -57 -261 -13 -454 78 -537 254 -29 62 -32 76 -31 163 0 130 25 193 110
278 74 74 155 115 270 138 200 40 531 -38 571 -133 7 -18 19 -33 26 -33 12 0
157 257 157 280 0 8 -7 10 -22 5 -39 -13 -79 -7 -193 29 -60 19 -149 42 -196
50 -104 19 -321 20 -435 0z"/>
<path d="M5518 4346 c-174 -31 -291 -88 -398 -196 -117 -116 -166 -225 -176
-385 -19 -293 184 -547 504 -630 166 -44 434 -37 596 15 382 123 560 502 396
845 -48 101 -166 216 -280 273 -130 65 -216 83 -405 88 -105 2 -186 -1 -237
-10z m316 -200 c150 -29 269 -114 328 -234 31 -63 33 -72 33 -177 0 -98 -3
-116 -27 -167 -70 -149 -217 -243 -399 -255 -257 -17 -467 112 -515 316 -58
243 85 461 339 516 82 18 149 18 241 1z"/>
<path d="M3340 4330 c0 -5 13 -16 29 -24 16 -9 31 -23 33 -33 9 -33 6 -1058
-3 -1081 -5 -12 -20 -27 -35 -32 -14 -6 -24 -16 -21 -23 3 -9 139 -13 620 -15
l616 -2 19 -25 c38 -47 42 -33 42 130 0 123 -3 155 -13 155 -8 0 -19 -11 -25
-24 -7 -17 -22 -27 -44 -31 -18 -3 -217 -4 -443 -3 l-410 3 -3 474 c-2 431 -1
476 14 493 10 10 23 18 31 18 7 0 13 7 13 15 0 13 -30 15 -210 15 -130 0 -210
-4 -210 -10z"/>
<path d="M6930 4325 c0 -8 6 -15 13 -15 8 0 21 -8 30 -18 15 -17 17 -70 17
-558 0 -296 -4 -544 -8 -550 -4 -6 -17 -16 -29 -23 -13 -6 -23 -19 -23 -27 0
-14 56 -15 538 -11 345 3 555 9 587 16 136 31 257 123 290 220 22 66 17 189
-9 242 -24 51 -81 105 -137 132 -27 12 -48 25 -49 28 0 3 22 20 49 37 90 59
131 150 118 264 -17 141 -127 241 -294 267 -46 7 -257 11 -582 11 -448 0 -511
-2 -511 -15z m1021 -202 c32 -12 46 -26 60 -57 23 -51 23 -74 3 -125 -12 -30
-26 -45 -58 -61 -40 -19 -60 -20 -359 -20 l-317 0 0 140 0 140 315 0 c269 0
321 -2 356 -17z m-46 -474 c33 -6 72 -17 88 -25 91 -48 91 -221 -2 -268 -54
-28 -161 -37 -436 -34 l-270 3 -3 155 c-1 85 0 160 2 167 7 17 533 18 621 2z"/>
<path d="M9196 4332 c-3 -5 8 -19 24 -31 56 -40 73 -2 -332 -756 -42 -77 -95
-176 -118 -220 -69 -129 -81 -147 -117 -161 -18 -8 -33 -21 -33 -29 0 -13 32
-15 218 -15 120 0 221 3 225 6 9 9 -4 34 -18 34 -20 0 -45 29 -45 52 0 12 25
68 57 125 l56 103 308 0 307 0 20 -32 c11 -18 38 -69 61 -113 46 -90 46 -118
0 -134 -16 -5 -29 -17 -29 -25 0 -14 30 -16 244 -16 179 0 247 3 252 12 4 6 2
14 -4 16 -54 18 -99 74 -181 226 -48 87 -171 310 -325 588 -121 218 -147 273
-144 300 2 23 10 36 25 43 13 5 25 15 27 22 4 10 -44 13 -234 13 -131 0 -241
-4 -244 -8z m336 -504 c57 -112 104 -209 106 -215 3 -10 -46 -13 -218 -13
l-221 0 68 133 c91 180 154 297 159 297 2 0 50 -91 106 -202z"/>
<path d="M10600 4330 c0 -5 13 -16 29 -24 16 -9 31 -23 35 -31 11 -30 7 -1065
-5 -1086 -5 -11 -22 -24 -35 -30 -14 -5 -24 -15 -21 -22 3 -9 142 -13 626 -17
538 -4 623 -7 631 -20 5 -8 10 -18 10 -22 0 -5 7 -8 15 -8 13 0 15 24 15 155
0 123 -3 155 -13 155 -8 0 -19 -11 -25 -24 -7 -17 -22 -27 -44 -31 -18 -3
-217 -4 -443 -3 l-410 3 -3 469 c-2 408 0 472 13 493 9 12 22 23 30 23 8 0 15
7 15 15 0 13 -30 15 -210 15 -130 0 -210 -4 -210 -10z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
